.home-container1 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  background-color: #0F0F0F;
}
.home-first-look {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  border-color: #51515A;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
  border-bottom-width: 1px;
}
.home-hero {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-content1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
}
.home-main1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-heading1 {
  font-size: 46px;
  text-align: center;
}
.home-text100 {
  color: #ffffff;
}
.home-text101 {
  color: #0029ff;
}
.home-text102 {
  color: #0029ff;
}
.home-text104 {
  color: #ffffff;
}
.home-text106 {
  color: #ffffff;
}
.home-text107 {
  color: #ffffff;
}
.home-text109 {
  color: #ffffff;
}
.home-caption {
  font-size: 20px;
  align-self: flex-start;
  font-family: Poppins;
  line-height: 30px;
}
.home-text111 {
  color: #dcf541;
  font-weight: 600;
}
.home-text112 {
  color: #ffffff;
}
.home-text113 {
  color: #ffffff;
}
.home-text116 {
  color: #dcf541;
  font-style: normal;
  font-weight: 600;
}
.home-text117 {
  color: #ffffff;
}
.home-text118 {
  color: #ffffff;
}
.home-text119 {
  color: #ffffff;
}
.home-text120 {
  color: #dcf541;
  font-style: normal;
  font-weight: 600;
}
.home-text122 {
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
}
.home-text123 {
  color: #ffffff;
}
.home-text124 {
  color: #ffffff;
}
.home-text125 {
  color: #ffffff;
}
.home-text127 {
  color: #ff0000;
  font-style: normal;
  font-weight: 700;
}
.home-text128 {
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
}
.home-buttons {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-link1 {
  display: contents;
}
.home-get-started {
  width: 339px;
  display: flex;
  text-decoration: none;
  background-color: #80FF44;
}
.home-text130 {
  color: rgb(12, 16, 12);
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 24px;
}
.home-image1 {
  top: 150px;
  right: 0px;
  width: 650px;
  height: 900px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image2 {
  top: 0px;
  right: 0px;
  width: 100%;
  margin: auto;
  position: absolute;
  object-fit: cover;
}
.home-image3 {
  display: none;
}
.home-more-info {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
}
.home-text131 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.home-features1 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  user-select: none;
  border-color: #5A5A5A;
  margin-bottom: 120px;
  flex-direction: row;
  justify-content: center;
  border-bottom-width: 1px;
}
.home-feature1 {
  border-color: var(--dl-color-gray-500);
}
.home-text140 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.home-text141 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.home-text142 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.home-note {
  gap: 140px;
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-content2 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  max-width: 520px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-main2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-heading2 {
  color: rgb(220, 180, 0);
}
.home-text143 {
  color: #80ff44;
}
.home-text144 {
  color: #ffffff;
}
.home-text145 {
  color: #ffffff;
}
.home-paragraph1 {
  color: #ffffff;
  font-family: Poppins;
  line-height: 28px;
}
.home-image5 {
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
}
.home-image6 {
  object-fit: cover;
}
.home-test {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  border-color: #51515A;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  border-top-width: 1px;
}
.home-header11 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-left1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-right1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-paragraph2 {
  width: 100%;
  max-width: 480px;
}
.home-text149 {
  color: #80ff44;
}
.home-text150 {
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
}
.home-text151 {
  color: #80ff44;
}
.home-text152 {
  font-style: normal;
  font-weight: 600;
}
.home-text154 {
  color: #80ff44;
}
.home-cards1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-header12 {
  color: rgb(255, 255, 255);
  display: inline-block;
}
.home-description1 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-family: Poppins;
  line-height: 28px;
}
.home-header13 {
  color: rgb(255, 255, 255);
  display: inline-block;
}
.home-description2 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-family: Poppins;
  line-height: 28px;
}
.home-cards2 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-header14 {
  color: rgb(255, 255, 255);
  display: inline-block;
}
.home-description3 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-family: Poppins;
  line-height: 28px;
}
.home-pricing1 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
}
.home-cube {
  right: -150px;
  width: 210px;
  bottom: -80px;
  height: 210px;
  margin: auto;
  display: flex;
  position: absolute;
  transform: rotateX(240deg) rotateY(50deg) rotateZ(-110deg);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform-style: preserve-3d;
}
.home-top {
  transform: translateZ(-210px);
  background-image: linear-gradient(270deg, rgb(253, 253, 253) 0.00%,rgb(178, 178, 178) 100.00%);
}
.home-front {
  transform: rotateX(90deg);
  background: grey;
  background-image: linear-gradient(90deg, rgb(247, 247, 247) 0.00%,rgb(203, 203, 203) 100.00%);
  transform-origin: bottom;
}
.home-left2 {
  width: 210px;
  transform: translateZ(-210px) rotateY(90deg);
  background-image: linear-gradient(90deg, rgb(247, 247, 247) 0.00%,rgb(203, 203, 203) 100.00%);
  transform-origin: right;
}
.home-pricing2 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  z-index: 50;
  max-width: 1200px;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
}
.home-header15 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 900px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-left3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-heading4 {
  font-size: 32px;
}
.home-text173 {
  color: #dcb400;
}
.home-right2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-paragraph3 {
  width: 801px;
  font-size: 18px;
  max-width: 600px;
  text-align: center;
  line-height: 32px;
}
.home-plans-container {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-plans {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  z-index: 50;
  max-width: 1200px;
  align-items: center;
  border-radius: 50px;
  flex-direction: row;
  justify-content: center;
}
.home-plan1 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #292929;
}
.home-details1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-header16 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-name1 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 600;
}
.home-pricing3 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-price1 {
  color: rgb(255, 255, 255);
}
.home-duration1 {
  color: #B3B3B3;
  font-size: 20px;
  font-family: Poppins;
  line-height: 40px;
}
.home-description4 {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Poppins";
  line-height: 22px;
}
.home-buy-details1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-link2 {
  display: contents;
}
.home-buy1 {
  width: 100%;
  display: flex;
  box-shadow: 5px 5px 10px 0px #272727;
  text-decoration: none;
  background-color: #ffffff;
}
.home-text179 {
  color: rgb(12, 16, 12);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}
.home-features2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading5 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}
.home-list1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-label10 {
  color: rgb(194, 198, 204);
  display: inline-block;
}
.home-label11 {
  color: rgb(194, 198, 204);
  display: inline-block;
}
.home-label12 {
  color: rgb(194, 198, 204);
  display: inline-block;
}
.home-label13 {
  color: rgb(194, 198, 204);
  display: inline-block;
}
.home-label14 {
  color: rgb(194, 198, 204);
  display: inline-block;
}
.home-plan2 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #6f6d0b;
}
.home-details2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-header17 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-name2 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 700;
}
.home-pricing4 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-price2 {
  color: rgb(255, 255, 255);
}
.home-duration2 {
  color: #B3B3B3;
  font-size: 20px;
  font-family: Poppins;
  line-height: 40px;
}
.home-description5 {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Poppins";
  line-height: 22px;
}
.home-buy-details2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-buy2 {
  width: 100%;
  display: flex;
  box-shadow: 5px 5px 10px 0px #464646;
  background-color: #ffffff;
}
.home-text184 {
  color: rgb(12, 16, 12);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}
.home-features3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading6 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}
.home-list2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-label15 {
  color: rgb(194, 198, 204);
  display: inline-block;
}
.home-label16 {
  color: rgb(194, 198, 204);
  display: inline-block;
}
.home-label17 {
  color: rgb(194, 198, 204);
  display: inline-block;
}
.home-label18 {
  color: rgb(194, 198, 204);
  display: inline-block;
}
.home-label19 {
  color: rgb(194, 198, 204);
  display: inline-block;
}
.home-plan3 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #0061ff;
}
.home-details3 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-header18 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-name3 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 600;
}
.home-pricing5 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-price3 {
  color: rgb(255, 255, 255);
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
}
.home-duration3 {
  color: #B3B3B3;
  font-size: 20px;
  font-family: Poppins;
  line-height: 40px;
}
.home-description6 {
  color: rgba(255, 255, 255, 0.8);
}
.home-buy-details3 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-buy3 {
  width: 100%;
  display: flex;
  box-shadow: 5px 5px 10px 0px #656565;
  background-color: #ffffff;
}
.home-text189 {
  color: rgb(12, 16, 12);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}
.home-features4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading7 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}
.home-list3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-label20 {
  color: rgb(194, 198, 204);
  display: inline-block;
}
.home-label21 {
  color: rgb(194, 198, 204);
  display: inline-block;
}
.home-label22 {
  color: rgb(194, 198, 204);
  display: inline-block;
}
.home-label23 {
  color: rgb(194, 198, 204);
  display: inline-block;
}
.home-label24 {
  color: rgb(194, 198, 204);
  display: inline-block;
}
.home-help {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-text192 {
  color: #ffffff;
  font-family: Poppins;
  line-height: 24px;
}
.home-contact-support {
  gap: var(--dl-space-space-oneandhalfunits);
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: #80FF44;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  border-bottom-width: 1px;
}
.home-contact-support:hover {
  opacity: 0.5;
}
.home-link3 {
  display: contents;
}
.home-text195 {
  color: rgb(128, 255, 68);
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
.home-text196 {
  font-style: normal;
}
.home-text197 {
  font-style: normal;
}
.home-footer {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.home-content3 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-main-content {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-content4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-main3 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header19 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-branding {
  width: 120px;
  object-fit: cover;
}
.home-socials {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-link4 {
  display: contents;
}
.home-link5 {
  display: contents;
}
.home-link6 {
  display: contents;
}
.home-link7 {
  display: contents;
}
.home-text199 {
  color: var(--dl-color-success-500);
}
.home-text201 {
  color: var(--dl-color-theme-secondary1);
}
.home-text203 {
  color: var(--dl-color-theme-primary2);
}
.home-text204 {
  color: var(--dl-color-theme-primary2);
}
.home-text206 {
  color: var(--dl-color-theme-accent1);
  font-style: italic;
  font-weight: 100;
}
.home-text210 {
  color: var(--dl-color-theme-secondary1);
}
.home-text211 {
  color: var(--dl-color-theme-secondary1);
}
.home-copyright1 {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text212 {
  color: rgb(196, 196, 196);
  font-size: 14px;
  align-self: flex-start;
  font-family: "Poppins";
  line-height: 21px;
}
.home-copyright2 {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text213 {
  color: #C4C4C4;
  width: 100%;
  font-size: 14px;
  font-family: Poppins;
  line-height: 21px;
}
.home-container3 {
  display: contents;
}
@media(max-width: 1200px) {
  .home-text157 {
    color: var(--dl-color-theme-secondary1);
  }
  .home-text158 {
    color: var(--dl-color-theme-secondary1);
  }
  .home-description2 {
    color: var(--dl-color-theme-secondary1);
  }
  .home-text167 {
    color: var(--dl-color-theme-secondary1);
  }
  .home-text198 {
    color: var(--dl-color-theme-secondary1);
  }
  .home-text199 {
    color: #ff8d01;
  }
  .home-text203 {
    color: #53ff00;
  }
  .home-text204 {
    color: #53ff00;
  }
  .home-text206 {
    font-style: italic;
    font-weight: 100;
  }
}
@media(max-width: 991px) {
  .home-first-look {
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-hero {
    gap: var(--dl-space-space-fourunits);
    flex: 1;
    position: relative;
    max-width: auto;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-content1 {
    position: relative;
    align-items: center;
  }
  .home-main1 {
    align-items: center;
  }
  .home-heading1 {
    text-align: left;
  }
  .home-caption {
    align-self: flex-start;
    text-align: left;
    line-height: 20px;
  }
  .home-text111 {
    color: #80ff44;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text112 {
    color: #ffffff;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text113 {
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text114 {
    color: #d4fb39;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text116 {
    color: #80ff44;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text117 {
    color: #d4fb39;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text118 {
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text119 {
    color: #d4fb39;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text120 {
    color: #80ff44;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text121 {
    color: #80ff44;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text122 {
    color: #ffffff;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text123 {
    color: #ffffff;
  }
  .home-text124 {
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text125 {
    color: #ffffff;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text127 {
    color: #ff0101;
    font-style: normal;
    font-family: Poppins;
    font-weight: 700;
    line-height: 28px;
  }
  .home-text128 {
    color: #ffffff;
    font-style: normal;
    font-family: Poppins;
    font-weight: 700;
    line-height: 28px;
  }
  .home-text129 {
    color: #ffffff;
  }
  .home-buttons {
    width: 100%;
  }
  .home-get-started {
    flex: 1;
  }
  .home-image1 {
    right: 0px;
    display: none;
    position: absolute;
    flex-direction: column;
  }
  .home-image3 {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .home-image4 {
    width: 600px;
  }
  .home-text131 {
    text-align: center;
  }
  .home-note {
    gap: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .home-content2 {
    max-width: 100%;
  }
  .home-main2 {
    align-items: flex-start;
  }
  .home-test {
    gap: var(--dl-space-space-threeunits);
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .home-header11 {
    gap: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-cards1 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-text157 {
    color: var(--dl-color-theme-secondary1);
  }
  .home-text158 {
    color: var(--dl-color-theme-secondary1);
  }
  .home-description2 {
    color: var(--dl-color-theme-secondary1);
  }
  .home-cards2 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-text167 {
    color: var(--dl-color-theme-secondary1);
  }
  .home-plans {
    flex-direction: column;
  }
  .home-content3 {
    gap: var(--dl-space-space-threeunits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-copyright1 {
    display: none;
  }
  .home-copyright2 {
    display: flex;
  }
}
@media(max-width: 767px) {
  .home-content1 {
    gap: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading1 {
    font-size: 46px;
  }
  .home-text100 {
    color: #ffffff;
  }
  .home-text101 {
    color: #0061ff;
  }
  .home-text102 {
    color: #0061ff;
  }
  .home-text103 {
    color: #ffffff;
  }
  .home-text104 {
    color: #ffffff;
  }
  .home-text105 {
    color: #ffffff;
  }
  .home-text106 {
    color: #ffffff;
  }
  .home-text107 {
    color: #ffffff;
  }
  .home-text108 {
    color: #ffffff;
  }
  .home-text109 {
    color: #ffffff;
  }
  .home-caption {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-family: Poppins;
    line-height: 24px;
  }
  .home-get-started {
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-image4 {
    width: 500px;
  }
  .home-more-info {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .home-text131 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    text-align: center;
  }
  .home-text133 {
    color: #dcf541;
  }
  .home-features1 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-top: var(--dl-space-space-threeunits);
    border-color: #5A5A5A;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-feature1 {
    width: 100%;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-text140 {
    font-size: 20px;
  }
  .home-feature2 {
    width: 100%;
    border-color: #5A5A5A;
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 1px;
  }
  .home-text141 {
    font-size: 20px;
  }
  .home-feature3 {
    width: 100%;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-text142 {
    font-size: 20px;
  }
  .home-note {
    gap: var(--dl-space-space-threeunits);
  }
  .home-main2 {
    width: 100%;
    align-items: flex-start;
  }
  .home-heading2 {
    font-size: 28px;
  }
  .home-paragraph1 {
    line-height: 24px;
  }
  .home-text146 {
    line-height: 24px;
  }
  .home-left1 {
    gap: var(--dl-space-space-unit);
  }
  .home-text157 {
    color: #ffffff;
  }
  .home-text158 {
    color: #ffffff;
  }
  .home-text162 {
    color: #ffffff;
  }
  .home-text163 {
    color: #ffffff;
  }
  .home-text167 {
    color: var(--dl-color-theme-secondary1);
  }
  .home-cube {
    right: -250px;
  }
  .home-plan1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-plan2 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-plan3 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-help {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-content4 {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-main3 {
    gap: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .home-header10 {
    align-items: flex-start;
  }
  .home-heading1 {
    width: 322px;
    height: 289px;
    font-size: 46px;
    max-width: 280px;
    align-self: stretch;
    text-align: left;
    margin-right: var(--dl-space-space-threeunits);
    padding-bottom: 39px;
  }
  .home-text100 {
    color: #ffffff;
  }
  .home-text101 {
    color: #0061ff;
  }
  .home-text102 {
    color: #0061ff;
  }
  .home-text103 {
    color: #ffffff;
  }
  .home-text104 {
    color: #ffffff;
  }
  .home-text105 {
    color: #ffffff;
  }
  .home-text106 {
    color: #ffffff;
  }
  .home-text107 {
    color: #ffffff;
  }
  .home-text108 {
    color: #ffffff;
  }
  .home-text109 {
    color: #ffffff;
  }
  .home-text110 {
    color: #ffffff;
  }
  .home-caption {
    align-self: center;
    text-align: left;
    line-height: 20px;
  }
  .home-text111 {
    color: #80ff44;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text112 {
    color: #ffffff;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text113 {
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text114 {
    color: #d4fb39;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text116 {
    color: #80ff44;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text117 {
    color: #d4fb39;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text118 {
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text119 {
    color: #d4fb39;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text120 {
    color: #80ff44;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text121 {
    color: #80ff44;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text122 {
    color: #ffffff;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text123 {
    color: #ffffff;
  }
  .home-text124 {
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text125 {
    color: #ffffff;
    font-weight: 500;
    line-height: 28px;
  }
  .home-text127 {
    color: #ff0101;
    font-style: normal;
    font-family: Poppins;
    font-weight: 700;
    line-height: 28px;
  }
  .home-text128 {
    color: #ffffff;
    font-style: normal;
    font-family: Poppins;
    font-weight: 700;
    line-height: 28px;
  }
  .home-text129 {
    color: #ffffff;
  }
  .home-get-started {
    width: 351px;
    box-shadow: 2px 2px 5px 0px #33f100;
    margin-left: 0px;
    background-color: #ffffff;
    border-top-left-radius: var(--dl-radius-radius-cardradius);
    border-top-right-radius: var(--dl-radius-radius-cardradius);
    border-bottom-left-radius: var(--dl-radius-radius-cardradius);
    border-bottom-right-radius: var(--dl-radius-radius-cardradius);
  }
  .home-text130 {
    color: rgb(12, 16, 12);
  }
  .home-image4 {
    width: 400px;
  }
  .home-text131 {
    color: rgb(255, 255, 255);
    align-self: center;
    font-style: normal;
    font-weight: 500;
  }
  .home-text133 {
    color: #80ff44;
  }
  .home-text134 {
    color: #80ff44;
  }
  .home-text140 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
  }
  .home-text141 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
  }
  .home-text142 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
  }
  .home-heading2 {
    fill: #dcf541;
    color: rgb(224, 2, 2);
    font-size: 32px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
  .home-text143 {
    color: #80ff44;
  }
  .home-text145 {
    color: #ffffff;
  }
  .home-paragraph1 {
    color: rgb(204, 204, 204);
    font-family: Poppins;
    line-height: 28px;
  }
  .home-text146 {
    line-height: 24px;
  }
  .home-image6 {
    width: 100%;
  }
  .home-header12 {
    color: rgb(255, 255, 255);
  }
  .home-description1 {
    color: rgb(194, 198, 204);
    font-family: Poppins;
    line-height: 28px;
  }
  .home-text157 {
    color: #ffffff;
  }
  .home-text158 {
    color: #ffffff;
  }
  .home-header13 {
    color: rgb(255, 255, 255);
  }
  .home-text160 {
    color: #000000;
  }
  .home-description2 {
    color: var(--dl-color-theme-neutral-dark);
  }
  .home-text162 {
    color: var(--dl-color-theme-neutral-dark);
  }
  .home-text163 {
    color: var(--dl-color-theme-neutral-dark);
  }
  .home-header14 {
    color: rgb(255, 255, 255);
  }
  .home-description3 {
    color: rgb(194, 198, 204);
    font-family: Poppins;
    line-height: 28px;
  }
  .home-pricing2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading4 {
    font-size: 32px;
    text-align: center;
  }
  .home-text173 {
    color: #dcb400;
  }
  .home-paragraph3 {
    font-size: 16px;
  }
  .home-text174 {
    font-style: normal;
    font-weight: 600;
  }
  .home-text175 {
    font-style: normal;
    font-weight: 600;
  }
  .home-price1 {
    color: rgb(255, 255, 255);
  }
  .home-description4 {
    color: rgba(255, 255, 255, 0.8);
    font-family: Poppins;
    line-height: 22px;
  }
  .home-buy1 {
    box-shadow: 5px 5px 10px 0px #171717;
    background-color: #ffffff;
  }
  .home-label10 {
    color: rgb(194, 198, 204);
  }
  .home-label11 {
    color: rgb(194, 198, 204);
  }
  .home-label12 {
    color: rgb(194, 198, 204);
  }
  .home-label13 {
    color: rgb(194, 198, 204);
  }
  .home-label14 {
    color: rgb(194, 198, 204);
  }
  .home-plan2 {
    background-color: #36ff00;
  }
  .home-name2 {
    color: #000000;
  }
  .home-price2 {
    color: rgb(0, 0, 0);
  }
  .home-duration2 {
    color: #000000;
  }
  .home-description5 {
    color: rgba(0, 0, 0, 0.8);
    font-family: Poppins;
    line-height: 22px;
  }
  .home-buy2 {
    box-shadow: 5px 5px 10px 0px #525252;
  }
  .home-heading6 {
    color: #000000;
  }
  .home-label15 {
    color: rgb(0, 0, 0);
  }
  .home-label16 {
    color: #000000;
  }
  .home-label17 {
    color: #000000;
  }
  .home-label18 {
    color: #000000;
  }
  .home-text188 {
    font-style: normal;
    font-weight: 500;
  }
  .home-label19 {
    color: rgb(0, 0, 0);
  }
  .home-name3 {
    color: rgb(255, 255, 255);
    font-style: normal;
    font-weight: 600;
  }
  .home-price3 {
    color: rgb(255, 255, 255);
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
  }
  .home-description6 {
    color: rgba(255, 255, 255, 0.8);
  }
  .home-buy3 {
    box-shadow: 5px 5px 10px 0px #525252;
  }
  .home-label20 {
    color: rgb(194, 198, 204);
  }
  .home-label21 {
    color: rgb(194, 198, 204);
  }
  .home-label22 {
    color: rgb(194, 198, 204);
  }
  .home-label23 {
    color: rgb(194, 198, 204);
  }
  .home-label24 {
    color: rgb(194, 198, 204);
  }
  .home-text196 {
    color: #449aff;
    font-style: inherit;
    font-weight: 700;
  }
  .home-text197 {
    font-style: normal;
  }
  .home-footer {
    height: 304px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-content3 {
    height: 298px;
    padding-bottom: 37px;
  }
  .home-main3 {
    width: 440px;
  }
  .home-text198 {
    font-family: Poppins;
  }
  .home-text199 {
    color: #ffab00;
  }
  .home-text201 {
    color: var(--dl-color-theme-secondary1);
  }
  .home-text203 {
    color: #00ff05;
  }
  .home-text204 {
    color: #00ff05;
  }
  .home-text206 {
    color: #ffffff;
    font-style: italic;
    font-weight: 100;
  }
  .home-text210 {
    color: var(--dl-color-theme-secondary1);
  }
  .home-text211 {
    color: var(--dl-color-theme-secondary1);
  }
  .home-text213 {
    color: rgb(196, 196, 196);
    font-size: 14px;
    font-family: Poppins;
    line-height: 21px;
    margin-bottom: var(--dl-space-space-twounits);
  }
}
