.includes-mark {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.includes-icon1 {
  display: flex;
  padding: 5px;
  align-items: center;
  border-radius: 50%;
  flex-direction: row;
  justify-content: center;
  background-color: #414141;
}
.includes-icon2 {
  fill: #C6FF4B;
  width: 12px;
  height: 12px;
}
.includes-label2 {
  color: rgb(194, 198, 204);
  display: inline-block;
}






















@media(max-width: 479px) {
  .includes-label2 {
    color: rgb(194, 198, 204);
  }
}
